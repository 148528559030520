



















































import FileResource from '@/models/graphql/FileResource';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { Component, Prop } from 'vue-property-decorator';
import MediaBackground from '@/components/wrappers/MediaBackground.vue';
import VideoBackground from '@/components/wrappers/VideoBackground.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: {
    MediaBackground,
    VideoBackground,
    ButtonComponent,
  },
})
export default class MediaBannerWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected VideoGap: Record<string, number> = {
    NONE: 0,
    QUARTER: 24,
    HALF: this.isDesktop ? 48 : 40,
    // eslint-disable-next-line no-nested-ternary
    FULL: this.isDesktop ? 96 : this.isTablet ? 80 : 40,
  };

  @Prop({ required: false })
  private height!: number;

  @Prop({ required: false })
  private parallax!: boolean;

  @Prop({ required: false })
  private layout!: string;

  @Prop({
    required: false,
    default: null,
  })
  private mediaUrl!: FileResource;

  @Prop({
    required: false,
    default: true,
  })
  private mute!: string;

  @Prop({ required: false })
  private innerTopGap!: string;

  @Prop({ required: false })
  private innerBottomGap!: string;

  @Prop({ required: false })
  private imageUrl!: { path: string; id: number };

  @Prop({
    required: false,
    default: '',
  })
  private title!: string;

  @Prop({
    required: false,
    default: '',
  })
  private subtitle!: string;

  @Prop({
    required: false,
    default: '',
  })
  private btnTitle!: string;

  @Prop({
    required: false,
    default: '',
  })
  private btnRoute!: string;

  get showImageUrl(): string {
    let { imageUrl } = this;
    if (this.payload) {
      imageUrl = this.payload.imageUrl as { path: string; id: number };
    }
    if (imageUrl && imageUrl.path) {
      return imageUrl.path;
    }
    return '';
  }

  get bindData(): object {
    if (this.isVideo) {
      return {
        class: !this.isParallax && this.innerGaps,
        containerHeight: this.mediaHeight,
        imageUrl: this.showImageUrl,
        parallax: this.isParallax,
        sources: [this.mediaSrc],
        videoInnerGap: !this.isParallax && this.videoInnerGap,
      };
    }
    return {
      backgroundVariant: this.backgroundVariant,
      class: this.innerGaps,
      containerHeight: this.mediaHeight,
      img: this.mediaSrc,
      parallax: this.isParallax,
    };
  }

  get component(): object {
    if (this.isVideo) {
      return VideoBackground;
    }
    return MediaBackground;
  }

  get isParallax(): boolean {
    let { parallax } = this;
    if (this.payload) {
      parallax = this.payload.parallax as boolean;
    }
    return parallax;
  }

  protected get innerGaps(): string {
    const list: string[] = [];
    let {
      innerTopGap,
      innerBottomGap,
    } = this;
    if (this.payload) {
      innerTopGap = this.payload.innerTopGap as string;
      innerBottomGap = this.payload.innerBottomGap as string;
    }
    if (innerTopGap) {
      list.push(this.WidgetTopGap[innerTopGap]);
    }
    if (this.innerBottomGap) {
      list.push(this.WidgetBottomGap[innerBottomGap]);
    }
    return list.join(' ')
      .trim();
  }

  protected get videoInnerGap(): number {
    let innerGap = 0;
    let {
      innerTopGap,
      innerBottomGap,
    } = this;
    if (this.payload) {
      innerTopGap = this.payload.innerTopGap as string;
      innerBottomGap = this.payload.innerBottomGap as string;
    }
    if (innerTopGap) {
      innerGap += this.VideoGap[innerTopGap];
    }
    if (innerBottomGap) {
      innerGap += this.VideoGap[innerBottomGap];
    }
    return innerGap;
  }

  private get isVideo(): boolean {
    let { mediaUrl } = this;
    if (this.payload) {
      mediaUrl = this.payload.mediaUrl as FileResource;
    }
    return !!mediaUrl?.path && mediaUrl.path.includes('.webm');
  }

  private get mediaSrc(): string {
    let { mediaUrl } = this;
    if (this.payload) {
      mediaUrl = this.payload.mediaUrl as FileResource;
    }
    if (!mediaUrl?.path) {
      return '';
    }

    if (mediaUrl.path?.includes('.webm')) {
      return `https:${mediaUrl.path}`;
    }
    return FileResourceHelper.getImagePathWithSize(mediaUrl.path || '', 'w1920');
  }

  private get mediaHeight(): number {
    if (this.$slots.default) return 0;
    let { height } = this;
    if (this.payload) {
      height = this.payload.height as number;
    }
    return height;
  }

  private get backgroundVariant(): string {
    let { background } = this;
    if (this.payload) {
      background = this.payload.background as string;
    }
    return this.WidgetBackground[background];
  }

  private get navigateTo(): string {
    let { btnRoute } = this;
    if (this.payload) {
      btnRoute = this.payload.btnRoute as string;
    }
    if (btnRoute) {
      const routeParams = this.$route.params;
      const splittedRoute = btnRoute.split('%');
      let newRoute = splittedRoute[0];
      if (splittedRoute.length > 2) {
        for (let index = 1; index < splittedRoute.length - 1; index++) {
          const element = splittedRoute[index];
          if (routeParams[element]) {
            newRoute += `${routeParams[element]}/`;
          } else {
            newRoute += `${splittedRoute[index]}/`;
          }
        }
        if (newRoute[newRoute.length - 1] === '/') {
          newRoute = newRoute.substring(0, newRoute.length - 2);
        }
      } else {
        newRoute = btnRoute;
      }
      return newRoute;
    }
    return '';
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    if (this.isVideo && this.isParallax) {
      let {
        innerTopGap,
        innerBottomGap,
      } = this;
      if (this.payload) {
        innerTopGap = this.payload.innerTopGap as string;
        innerBottomGap = this.payload.innerBottomGap as string;
      }
      (this.$el as HTMLElement).style.borderTop = `${this.VideoGap[innerTopGap]}px solid white`;
      (this.$el as HTMLElement).style.borderBottom = `${this.VideoGap[innerBottomGap]}px solid white`;
    }
  }
}
